import React from 'react'
import {Link}  from 'react-router-dom';
import shape1 from '../../images/about/about.png'
import VideoModal from '../../components/ModalVideo'

const AboutArea2 = ( props ) => {
    return(
        <div className="wpo-about-area-2 section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12 col-sm-12">
                        <div className="wpo-about-img">
                            <img src={shape1} alt=""/>
                            <div className="about-thumb-text">
                                <h2>190+</h2>
                                <h6>Affected Country</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-sm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-section-title">
                                <span>About Coronavirus</span>
                                <h2>General information about coronavirus disease </h2>
                            </div>
                            <span>The recent outbreak began in Wuhan, a city in the Hubei province of China. Reports of the first COVID-19.</span>
                            <p>Coronaviruses are common in certain species of animals, such as cattle and camels. Although the transmission of coronaviruses from animals to humans is rare.However, it remains unclear exactly how the virus first spread to humans.</p>
                            <div className="btns">
                                <Link to="/" className="theme-btn-s2">Discover More</Link>
                                <ul>
                                    <li className="video-holder">
                                        <VideoModal/>
                                    </li>
                                    <li className="video-text">
                                        <Link to="/">
                                            Watch Our Video
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AboutArea2;