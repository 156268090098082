import React, { Component } from 'react'
import {Link}  from 'react-router-dom';

import img1 from '../../images/blog/profile.png' 
import img2 from '../../images/recent-posts/img-1.jpg' 
import img3 from '../../images/recent-posts/img-2.jpg' 
import img4 from '../../images/recent-posts/img-3.jpg' 

import './style.css'

class BlogSideaber extends Component{

    subimtHandler = (e) => {
        e.preventDefault();
    }
    render(){
        return(
            <div className="col col-lg-4 col-md-12 col-12">
                <div className="wpo-blog-sidebar">
                    <div className="widget profile-widget">
                        <div className="profile-img">
                            <img src={img1} alt=""/>
                            <h2>Noumi Bartler</h2>
                            <p>Hi! here is our blog. Read our post - and be with us</p>
                        </div>
                        <div className="pro-social">
                            <ul>
                                <li><Link to="/blogsingle"><i className="fa fa-facebook"></i></Link></li>
                                <li><Link to="/blogsingle"><i className="fa fa-twitter"></i></Link></li>
                                <li><Link to="/blogsingle"><i className="fa fa-instagram"></i></Link></li>
                                <li><Link to="/blogsingle"><i className="fa fa-google"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="widget search-widget">
                        <form onSubmit={this.subimtHandler}>
                            <div>
                                <input type="text" className="form-control" placeholder="Enter Keyword..."/>
                                <button type="submit"><i className="fa fa-search"></i></button>
                            </div>
                        </form>
                    </div>
                    <div className="widget category-widget">
                        <h3>Categories</h3>
                        <ul>
                            <li><Link to="/blogsingle">Corona Virus<span>(25)</span></Link></li>
                            <li><Link to="/blogsingle">Headache & Sore Throat <span>(34)</span></Link></li>
                            <li><Link to="/blogsingle">Shortness Of Breath <span>(50)</span></Link></li>
                            <li><Link to="/blogsingle">Virus Information <span>(46)</span></Link></li>
                            <li><Link to="/blogsingle">Travelling <span>(15)</span></Link></li>
                            <li><Link to="/blogsingle">Shaking Chills <span>(48)</span></Link></li>
                        </ul>
                    </div>
                    <div className="widget recent-post-widget">
                        <h3>Recent posts</h3>
                        <div className="posts">
                            <div className="post">
                                <div className="img-holder">
                                    <img src={img2} alt=""/>
                                </div>
                                <div className="details">
                                    <h4><Link to="/blogsingle">What you need to know About Coronavirus</Link></h4>
                                    <span className="date">22 Sep 2019</span>
                                </div>
                            </div>
                            <div className="post">
                                <div className="img-holder">
                                    <img src={img3} alt=""/>
                                </div>
                                <div className="details">
                                    <h4><Link to="/blogsingle">The novel coronavirus is a serious threat.</Link></h4>
                                    <span className="date">22 Sep 2019</span>
                                </div>
                            </div>
                            <div className="post">
                                <div className="img-holder">
                                    <img src={img4} alt=""/>
                                </div>
                                <div className="details">
                                    <h4><Link to="/blogsingle">Desperate for Covid-19 answer U.S. doctors </Link></h4>
                                    <span className="date">22 Sep 2019</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="widget tag-widget">
                        <h3>Tags</h3>
                        <ul>
                            <li><Link to="/blogsingle">Corona</Link></li>
                            <li><Link to="/blogsingle">Virus</Link></li>
                            <li><Link to="/blogsingle">Privention</Link></li>
                            <li><Link to="/blogsingle">Covid-19</Link></li>
                            <li><Link to="/blogsingle">World Wide</Link></li>
                            <li><Link to="/blogsingle">Threat</Link></li>
                            <li><Link to="/blogsingle">Doctor</Link></li>
                            <li><Link to="/blogsingle">Health</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        )

    }
   
}
export default BlogSideaber;