import React from 'react'
import './style.css'
import ContactForm from '../ContactFrom'

import contact1 from '../../images/wpo-service/icon5.png' 
import contact2 from '../../images/wpo-service/icon6.png' 
import contact3 from '../../images/wpo-service/icon7.png' 

const ContactArea = () => {
    return(
        <section className="wpo-contact-area section-padding">
            <div className="wpo-wpo-contact-form-map">
                <div className="container">
                    <div className="col-12">
                        <div className="site-title text-center">
                            <h2>Let’s Talk</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-12">
                            <div className="row">
                                <div className="col col-lg-7 col-md-6 col-12">
                                    <div className="wpo-contact-form">
                                        <h2>Get In Touch</h2>
                                        <ContactForm/>
                                    </div>
                                </div>
                                <div className="col col-lg-5 col-md-6 col-12">
                                    <div className="wpo-contact-info">
                                        <div className="info-item">
                                            <h2>25 North Street,Dubai</h2>
                                            <div className="info-wrap">
                                                <div className="info-icon">
                                                    <img src={contact1} alt=""/>
                                                </div>
                                                <div className="info-text">
                                                    <span>Office Address</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info-item">
                                            <h2>info@edefytheme.com</h2>
                                            <div className="info-wrap">
                                                <div className="info-icon">
                                                    <img src={contact2} alt=""/>
                                                </div>
                                                <div className="info-text">
                                                    <span>Official Mail</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info-item">
                                            <h2>+91 256-987-239</h2>
                                            <div className="info-wrap">
                                                <div className="info-icon">
                                                    <img src={contact3} alt=""/>
                                                </div>
                                                <div className="info-text">
                                                    <span>Official Phone</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </section>
    )
}

export default ContactArea;