import React from 'react'
import shape1 from '../../images/spread/img-1.png'
import shape2 from '../../images/spread/img-2.png'
import shape3 from '../../images/spread/img-3.png'

const SpreadArea2 = ( props ) => {
    return(
        <div className="Spread-area-2 section-padding">
            <div className="container">
                <div className="col-12">
                    <div className="site-title text-center">
                        <span>Virus Spread</span>
                        <h2>How Virus Spread</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-6 col-12">
                        <div className="spread-item">
                            <div className="spread-img">
                                <img src={shape1} alt=""/>
                            </div>
                            <div className="spread-text">
                                <h2>Close contact with one another</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12">
                        <div className="spread-item">
                            <div className="spread-img">
                                <img src={shape2} alt=""/>
                            </div>
                            <div className="spread-text">
                                <h2>From infected person coughs or sneezes</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12">
                        <div className="spread-item">
                            <div className="spread-img">
                                <img src={shape3} alt=""/>
                            </div>
                            <div className="spread-text">
                                <h2>Touching a object that has the virus </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SpreadArea2;