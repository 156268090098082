import React from 'react'
import {Link} from 'react-router-dom'
import img1 from '../../images/blog/img-4.jpg'

import BlogSideaber from '../BlogSideaber'
import VideoModal from '../../components/ModalVideo'
import './style.css'

const BlogPage = ( props ) => {
    return(
        <section className="wpo-blog-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 col-md-12 col-12">
                        <div className="wpo-blog-content">
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={img1} alt=""/>
                                </div>
                                <ul className="entry-meta">
                                    <li><Link to="/blogsingle"> By Robert harry</Link></li>
                                    <li><Link to="/blogsingle"> 25 Feb 2020</Link></li>
                                </ul>
                                <h3><Link to="/blogsingle">What you need to know About Coronavirus</Link></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt of the graduate ut labore et dolore magna aliqua.</p>
                                <div className="entry-bottom">
                                    <Link to="/blogsingle" className="read-more">View More...</Link>
                                    <ul>
                                        <li><i className="fa fa-heart-o" aria-hidden="true"></i>80</li>
                                        <li><i className="fa fa-share-alt" aria-hidden="true"></i></li>
                                    </ul>
                                </div>
                                <div className="blog-thumb-badge">
                                    <div className="blog-thumb-text">
                                        <span>Health</span>
                                    </div>
                                </div>
                            </div>
                            <div className="post post-text-wrap">
                                <div className="blog-thumb-text">
                                    <span>Health</span>
                                </div>
                                <ul className="entry-meta">
                                    <li><Link to="/blogsingle"> By Robert harry</Link></li>
                                    <li><Link to="/blogsingle"> 25 Feb 2020</Link></li>
                                </ul>
                                <h3><Link to="/blogsingle">The novel coronavirus is a serious threat.</Link></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt of the graduate ut labore et dolore magna aliqua.</p>
                                <div className="entry-bottom">
                                    <Link to="/blogsingle" className="read-more">View More...</Link>
                                    <ul>
                                        <li><i className="fa fa-heart-o" aria-hidden="true"></i>80</li>
                                        <li><i className="fa fa-share-alt" aria-hidden="true"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="post post-text-wrap">
                                <div className="post-bloquote">
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                    <span>Robert Harry</span>
                                </div>
                                <div className="blog-thumb-text">
                                    <span>Travelling</span>
                                </div>
                                <ul className="entry-meta">
                                    <li><Link to="/blogsingle"> By Robert harry</Link></li>
                                    <li><Link to="/blogsingle"> 25 Feb 2020</Link></li>
                                </ul>
                                <h3><Link to="/blogsingle">What you need to know About Coronavirus</Link></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt of the graduate ut labore et dolore magna aliqua.</p>
                                <div className="entry-bottom">
                                    <Link to="/blogsingle" className="read-more">View More...</Link>
                                    <ul>
                                        <li><i className="fa fa-heart-o" aria-hidden="true"></i>80</li>
                                        <li><i className="fa fa-share-alt" aria-hidden="true"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="post format-video">
                                <div className="entry-media video-holder">
                                    <img src={img1} alt=""/>
                                    <div className="video-btn">
                                        <VideoModal/>
                                    </div>
                                </div>
                                <div className="blog-thumb-badge">
                                    <div className="blog-thumb-text">
                                        <span>Travelling</span>
                                    </div>
                                </div>
                                <ul className="entry-meta">
                                    <li><Link to="/blogsingle"> By Robert harry</Link></li>
                                    <li><Link to="/blogsingle"> 25 Feb 2020</Link></li>
                                </ul>
                                <h3><Link to="/blogsingle">What you need to know About Coronavirus</Link></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt of the graduate ut labore et dolore magna aliqua.</p>
                                <div className="entry-bottom">
                                    <Link to="/blogsingle" className="read-more">View More...</Link>
                                    <ul>
                                        <li><i className="fa fa-heart-o" aria-hidden="true"></i>80</li>
                                        <li><i className="fa fa-share-alt" aria-hidden="true"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="post post-text-wrap">
                                <h5><i className="fi flaticon-chain"></i>Risus commodo viverra maecenas accumsan lacus vel.</h5>
                                <div className="blog-thumb-text">
                                    <span>Travelling</span>
                                </div>
                                <ul className="entry-meta">
                                    <li><Link to="/blogsingle"> By Robert harry</Link></li>
                                    <li><Link to="/blogsingle"> 25 Feb 2020</Link></li>
                                </ul>
                                <h3><Link to="/blogsingle">What you need to know About Coronavirus.</Link></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt of the graduate ut labore et dolore magna aliqua.</p>
                                <div className="entry-bottom">
                                    <Link to="/blogsingle" className="read-more">View More...</Link>
                                    <ul>
                                        <li><i className="fa fa-heart-o" aria-hidden="true"></i>80</li>
                                        <li><i className="fa fa-share-alt" aria-hidden="true"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="pagination-wrapper pagination-wrapper-left">
                                <ul className="pg-pagination">
                                    <li>
                                        <Link to="/blog" aria-label="Previous">
                                            <i className="fa fa-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="active"><Link to="/blog">1</Link></li>
                                    <li><Link to="/blog">2</Link></li>
                                    <li><Link to="/blog">3</Link></li>
                                    <li>
                                        <Link to="/blog" aria-label="Next">
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <BlogSideaber/>
                </div>
            </div>
        </section>
    )
}
export default BlogPage;