import React, {Fragment} from 'react';
import Header from '../../components/header'
import Hero1 from '../../components/Hero1'
import LatestBlog from '../../components/LatestNews'
import SpreadArea from '../../components/SpreadArea'
import AboutArea from '../../components/AboutArea'
import Symtoms from '../../components/Symtoms'
import Banner from '../../components/Banner'
import WorkArea from '../../components/WorkArea'
import Report from '../../components/Report'
import Footer from '../../components/Footer'
import Scrollbar from '../../components/scrollbar'

const HomePage =() => {
    return(
        <Fragment>
            <Header/>
            <Hero1/>
            <SpreadArea/>
            <AboutArea/>
            <Symtoms/>
            <Banner/>
            <WorkArea/>
            <Report/>
            <LatestBlog/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage;