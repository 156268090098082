import React, {Fragment} from 'react';
import Header from '../../components/header'
import PageTitle from '../../components/PageTitle'
import SpreadArea2 from '../../components/SpreadArea2'
import AboutArea3 from '../../components/AboutArea3'
import Symtoms from '../../components/Symtoms'
import Banner from '../../components/Banner'
import WorkArea from '../../components/WorkArea'
import Footer from '../../components/Footer'
import Scrollbar from '../../components/scrollbar'

const AboutPage =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle bdTitle={'About Us'} subtitle={'About'}/>
            <AboutArea3/>
            <SpreadArea2/>
            <Symtoms/>
            <Banner/>
            <WorkArea/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default AboutPage;