import React from 'react'
import VideoModal from '../ModalVideo'
import img1 from '../../images/work/img-1.png'
import img2 from '../../images/work/img-2.png'
import img3 from '../../images/work/img-3.png'
import img4 from '../../images/work/img-4.png'
import img5 from '../../images/work/img-5.png'
import img6 from '../../images/work/img-6.png'
import img7 from '../../images/work/img-7.png'
import img8 from '../../images/work/img-4.png'

import './style.css'

const WorkArea = ( props ) => {
    return(
        <div className="work-area section-padding">
            <div className="container">
                <div className="col-12">
                    <div className="site-title text-center">
                        <span>Do & Don’t</span>
                        <h2>What Should We Do & Don’t</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12 col-12">
                        <div className="work-left">
                            <div className="work-item">
                                <div className="work-img">
                                    <img src={img1} alt=""/>
                                </div>
                                <div className="work-text">
                                    <h3>Wash Your Hands For 20sec</h3>
                                    <p>Use soap and water or a hand sanitizer with at least 60% alcohol:</p>
                                </div>
                            </div>
                            <div className="work-item">
                                <div className="work-img">
                                    <img src={img2} alt=""/>
                                </div>
                                <div className="work-text">
                                    <h3>Wear a Mask When go outside</h3>
                                    <p>Masks help protect others from catching you the Coronavirus,</p>
                                </div>
                            </div>
                            <div className="work-item">
                                <div className="work-img">
                                    <img src={img3} alt=""/>
                                </div>
                                <div className="work-text">
                                    <h3>Cover Nose When Sneezing</h3>
                                    <p>People who really need them, like the sick or health care professionals..</p>
                                </div>
                            </div>
                            <div className="work-item">
                                <div className="work-img">
                                    <img src={img4} alt=""/>
                                </div>
                                <div className="work-text">
                                    <h3>Boil Your Food Properly</h3>
                                    <p>Use soap and water or a hand sanitizer with at least 60% alcohol:</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12">
                        <div className="work-right">
                            <div className="work-item">
                                <div className="work-text">
                                    <h3>Don’t Eat Dangerous Animal</h3>
                                    <p>Use soap and water or a hand sanitizer with at least 60% alcohol:</p>
                                </div>
                                <div className="work-img">
                                    <img src={img5} alt=""/>
                                </div>
                            </div>
                            <div className="work-item">
                                <div className="work-text">
                                    <h3>Don’t Touch Your Face or Nose</h3>
                                    <p>Masks help protect others from catching you the Coronavirus,</p>
                                </div>
                                <div className="work-img">
                                     <img src={img6} alt=""/>
                                </div>
                            </div>
                            <div className="work-item">
                                <div className="work-text">
                                    <h3>Avoid Contact Sick People</h3>
                                    <p>People who really need them, like the sick or health care professionals..</p>
                                </div>
                                <div className="work-img">
                                    <img src={img7} alt=""/>
                                </div>
                            </div>
                            <div className="work-item">
                                <div className="work-text">
                                    <h3>Avoid Crowded Places</h3>
                                    <p>Use soap and water or a hand sanitizer with at least 60% alcohol:</p>
                                </div>
                                <div className="work-img">
                                     <img src={img8} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WorkArea;