import React from 'react'
import ModalVideo from 'react-modal-video'

 
class VideoModal extends React.Component {
 
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
 
  openModal () {
    this.setState({isOpen: true})
  }
 
  render () {
    return (
      <div>
        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='S31-qL_Ax5E' onClose={() => this.setState({isOpen: false})} />
        <button onClick={this.openModal}><i className="fa fa-play" aria-hidden="true"></i></button>
      </div>
    )
  }
}

export default VideoModal;