import React, {Fragment} from 'react';
import Header2 from '../../components/header2'
import Hero2 from '../../components/Hero2'
import LatestBlog from '../../components/LatestNews'
import SpreadArea from '../../components/SpreadArea'
import AboutArea2 from '../../components/AboutArea2'
import Symtoms from '../../components/Symtoms'
import Banner2 from '../../components/Banner2'
import WorkArea from '../../components/WorkArea'
import Report from '../../components/Report'
import Footer from '../../components/Footer'
import Scrollbar from '../../components/scrollbar'

const HomePage2 =() => {
    return(
        <Fragment>
            <Header2/>
            <Hero2/>
            <SpreadArea/>
            <AboutArea2/>
            <Symtoms/>
            <Banner2/>
            <WorkArea/>
            <Report/>
            <LatestBlog/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage2;