import React from 'react'
import VideoModal from '../ModalVideo'
import img1 from '../../images/banner2.png'

const Banner2 = ( props ) => {
    return(
        <div className="video-banner-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="banner-img">
                            <img src={img1} alt=""/>
                            
                        </div>
                    </div>
                </div>
            </div>
            <ul className="banner-video">
                <li className="video-holder">
                        <VideoModal/>
                </li>
            </ul>
        </div>
    )
}
export default Banner2;