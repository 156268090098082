import React, {Fragment} from 'react';
import Header from '../../components/header'
import PageTitle from '../../components/PageTitle'
import BlogPage from '../../components/BlogPage'
import Footer from '../../components/Footer'
import Scrollbar from '../../components/scrollbar'


const Blog =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle bdTitle={'Latest Blog'} subtitle={'Blog'}/>
            <BlogPage/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default Blog;