import React from 'react'
import VideoModal from '../ModalVideo'
import img1 from '../../images/report.png'

import './style.css'

const Report = ( props ) => {
    return(
        <div className="report-area section-padding">
        <div className="container">
            <div className="col-12">
                <div className="site-title text-center">
                    <span>Virus Reports</span>
                    <h2>Corona Virus Live Reports</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="report-img">
                        <img src={img1} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default Report;