import React from 'react'

import './style.css'
const PageTitle = ( props ) => {
    return(
        <section className="page-title">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <h2>{props.bdTitle}</h2>
                        <ol className="breadcrumb">
                            <li><a href="index.html">Home</a></li>
                            <li>{props.subtitle}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PageTitle;