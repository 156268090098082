import React, {Component} from 'react'
import './style.css'
import {Link} from 'react-router-dom'

import Logo from '../../images/logo.png'

class Footer extends Component {

    subimtHandler = (e) => {
        e.preventDefault();
    }

    render(){
        return(
            <footer className="wpo-site-footer">
                <div className="wpo-upper-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget about-widget">
                                    <div className="logo widget-title">
                                        <img src={Logo} alt=""/>
                                    </div>
                                    <p>Coronavirus COVID-19 is everywhere, from the front page of all the papers to the playground at school. </p>
                                    <ul>
                                        <li><Link to="/"><i className="fa fa-facebook"></i></Link></li>
                                        <li><Link to="/"><i className="fa fa-twitter"></i></Link></li>
                                        <li><Link to="/"><i className="fa fa-instagram"></i></Link></li>
                                        <li><Link to="/"><i className="fa fa-google"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget market-widget wpo-service-link-widget">
                                    <div className="widget-title">
                                        <h3>Contact </h3>
                                    </div>
                                    <p>online store with lots of cool and exclusive wpo-features</p>
                                    <div className="contact-ft">
                                        <ul>
                                            <li><i className="fa fa-map-marker" aria-hidden="true"></i>28 Street, New York City, USA</li>
                                            <li><i className="fa fa-mobile" aria-hidden="true"></i>+000123456789</li>
                                            <li><i className="fa fa-envelope-o" aria-hidden="true"></i>Carona@gmail.com</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget link-widget">
                                    <div className="widget-title">
                                        <h3>Useful Links</h3>
                                    </div>
                                    <ul>
                                        <li><Link to="/">About</Link></li>
                                        <li><Link to="/">Corona Map</Link></li>
                                        <li><Link to="/">How Spread</Link></li>
                                        <li><Link to="/">Do & Don’t</Link></li>
                                        <li><Link to="/">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget newsletter-widget">
                                    <div className="widget-title">
                                        <h3>Newsletter</h3>
                                    </div>
                                    <form onSubmit={this.subimtHandler}>
                                        <div className="input-1">
                                            <input type="email" className="form-control" placeholder="Email Address *" required=""/>
                                        </div>
                                        <div className="submit clearfix">
                                            <button type="submit">subscribe<i className="fa fa-angle-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="wpo-lower-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col col-md-6 col-sm-12 col-12">
                                <div className="term">
                                    <ul>
                                        <li><Link to="/">Privecy Policy</Link></li>
                                        <li><Link to="/">Terms & Condition</Link></li>
                                        <li><Link to="/">Cookies</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col col-md-6 col-sm-12 col-12">
                                <p className="copyright">&copy; 2020 Carona. All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
    
}

export default Footer;