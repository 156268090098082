import React, {Fragment} from 'react';
import Header from '../../components/header'
import PageTitle from '../../components/PageTitle'
import Report from '../../components/Report'
import Footer from '../../components/Footer'
import Scrollbar from '../../components/scrollbar'

const Map =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle bdTitle={'Corona Live Map'} subtitle={'Traker'}/>
            <Report/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default Map;