import React, {Fragment} from 'react';
import Header from '../../components/header'
import PageTitle from '../../components/PageTitle'
import ContactArea from '../../components/ContactArea'
import Footer from '../../components/Footer'
import Scrollbar from '../../components/scrollbar'

const ContactPage =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle bdTitle={'Contact Us'} subtitle={'Contact'}/>
             <ContactArea/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ContactPage;