import React, {Fragment} from 'react';
import Header from '../../components/header'
import PageTitle from '../../components/PageTitle'
import WorkArea from '../../components/WorkArea'
import Footer from '../../components/Footer'
import Scrollbar from '../../components/scrollbar'

const Prevention =() => {
    return(
        <Fragment>
            <Header/>
            <PageTitle bdTitle={'Privention'} subtitle={'Privention'}/>
            <WorkArea/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default Prevention;