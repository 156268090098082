import React from 'react'
import './style.css'
import {Link} from 'react-router-dom'

import blog1 from '../../images/blog/1.jpg'
import blog2 from '../../images/blog/2.jpg'
import blog3 from '../../images/blog/3.jpg'

const LatestBlog = () => {
    return(
        <div className="blog-area ptb-100-70">
            <div className="container">
                <div className="col-12">
                    <div className="site-title text-center">
                        <span>Blog Post</span>
                        <h2>Corona Virus Latest News</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 custom-grid  col-12">
                        <div className="blog-item wow zoomIn" data-wow-duration="2000ms">
                            <div className="blog-img">
                                <img src={blog1} alt=""/>
                            </div>
                            <div className="blog-content">
                                <ul className="post-meta">
                                    <li><Link to="/blog">Health</Link></li>
                                    <li> Jan 12,2019</li>
                                </ul>
                                <h3><Link to="/blog">What you need to know About Coronavirus</Link></h3>
                                <Link to="/blog">Continue</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 custom-grid col-12">
                        <div className="blog-item wow zoomIn" data-wow-duration="2000ms">
                            <div className="blog-img">
                                <img src={blog2} alt=""/>
                            </div>
                            <div className="blog-content">
                                <ul className="post-meta">
                                    <li><Link to="/blog">Health</Link></li>
                                    <li> Jan 12,2019</li>
                                </ul>
                                <h3><Link to="/blog">The novel coronavirus is a serious threat.</Link></h3>
                                <Link to="/blog">Continue</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 custom-grid col-12">
                        <div className="blog-item b-0 wow zoomIn" data-wow-duration="2000ms">
                            <div className="blog-img">
                                <img src={blog3} alt=""/>
                            </div>
                            <div className="blog-content">
                                <ul className="post-meta">
                                    <li><Link to="/blog">Health</Link></li>
                                    <li> Jan 12,2019</li>
                                </ul>
                                <h3><Link to="/blog">Desperate for Covid-19 answer U.S. doctors colleagues</Link></h3>
                                <Link to="/blog">Continue</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LatestBlog;