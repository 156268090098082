import React from 'react'
import Logo from '../../images/logo.png'
import {Link}  from 'react-router-dom'
import MobileMenu from '../../components/MobileMenu'
import simg from '../../images/support.png'

import './style.css'

const Header = () => {
    return(	
	<div className="header-style-1">
        <div className="container-fluid">
            <div className="header-content">
                <div className="logo">
                    <Link to="#" title=""><img src={Logo} alt=""/></Link>
                </div>
                <nav className="d-lg-block d-none header-b">
                    <ul>
                        <li><Link className="active" to="/home" title="">Home +</Link>
                            <ul>
                                <li><Link className="active" to="/home">Home</Link></li>
                                <li><Link to="/home2">Home-style-2</Link></li>
                            </ul>
                        </li>
                        <li><Link to="/about" title="">About</Link></li>
                        <li><Link  to="/">Pages +</Link>
                            <ul>
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/map">Corona Live Map</Link></li>
                            </ul>
                        </li>
                        <li><Link to="/privention">Privention</Link></li>
                        <li><Link to="/contact" title="">Contact</Link></li>
                        <li><Link to="/" title="">Blog +</Link>
                            <ul>
                                <li><Link to="/blog" title="">Blog</Link></li>
                                <li><Link to="/blogsingle" title="">Blog Single</Link></li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <div className="contact">
                    <div className="get-quote">
                        <Link to="/contact"><img src={simg} alt=""/> Call : +0123-456-789</Link>
                    </div>
                    <div className="cart-search-contact">
                        <Link to="/contact" className="theme-btn">Contact Us</Link>
                    </div>
                </div>
                <div className="clearfix"></div>
                
            </div>
            <MobileMenu/>
        </div>
    </div>
    )
}

export default Header;