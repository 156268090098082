import React from 'react'
import img1 from '../../images/symtoms/img-1.png'
import img2 from '../../images/symtoms/img-2.png'
import img3 from '../../images/symtoms/img-7.png'
import img4 from '../../images/symtoms/img-4.png'
import img5 from '../../images/symtoms/img-5.png'

import './style.css'

const Symtoms = ( props ) => {
    return(
        <div className="symptoms-area section-padding">
            <div className="container">
                <div className="col-12">
                    <div className="site-title text-center">
                        <span>Corona Symptoms</span>
                        <h2>Corona Virus Symptoms</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <div className="symptoms-left">
                            <div className="symptoms-item">
                                <div className="symptoms-text">
                                    <h3>Coughing & Sneezing</h3>
                                </div>
                                <div className="symptoms-img">
                                    <img src={img1} alt=""/>
                                </div>
                            </div>
                            <div className="symptoms-item">
                                <div className="symptoms-text">
                                    <h3>Strong Headacke</h3>
                                </div>
                                <div className="symptoms-img">
                                    <img src={img2} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="s-middle-img">
                            <img src={img3} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="symptoms-right">
                            <div className="symptoms-item">
                                <div className="symptoms-img">
                                    <img src={img4} alt=""/>
                                </div>
                                <div className="symptoms-text">
                                    <h3>Strong Fever</h3>
                                </div>
                            </div>
                            <div className="symptoms-item">
                                <div className="symptoms-img">
                                    <img src={img5} alt=""/>
                                </div>
                                <div className="symptoms-text">
                                    <h3>Shortness Of Breath</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Symtoms;