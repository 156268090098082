import React from 'react'
import {Link}  from 'react-router-dom';
import heroimg from '../../images/slider/hero-shape.png'

const Hero2 = ( props ) => {
    return(
        <section className="hero-style-1">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-7 slide-caption">
                        <h2 className="hero-circle wow fadeInDown" data-wow-duration="0.8s">Protect Yourself From CoronaVirus (2019-nCoV)</h2>
                        <p className="wow fadeInUp" data-wow-delay="1.0s">Here you will find the latest situation updates and data regarding the COVID-19 outbreak.</p>
                        <div className="wow fadeInUp" data-wow-delay="1.5s"><Link to="/" className="theme-btn">Discover More</Link></div>
                    </div>
                    <div className="col-lg-5">
                        <div className="slide-img wow fadeInRightSlow" data-wow-duration="2000ms">
                            <img src={heroimg} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
    </section>
    )
}
export default Hero2;